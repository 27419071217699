<template>
  <div class="social-container">
    <h5>Social</h5>
    <v-row class="btn-wrapper">
      <a :href="item.url" v-for="item in social" :key="item.name">
        <i v-if="item.lib == 'fa'" :class="item.icon1"></i>
        <v-icon v-else-if="item.lib == 'mdi'">{{ item.icon2 }} </v-icon>
      </a>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'social',

    data() {
      return {
        social: [
          {
            name: 'Instagram',
            url: 'https://www.instagram.com/xpofranchise',
            // mdi-icon:'';,
            lib: 'fa',
            icon1: 'fa fa-instagram', // Font Awesome
            icon2: 'fa fa-instagram', // Material Design Icons
          },
          {
            name: 'Facebook',
            url: 'https://www.facebook.com/xpofranchise',
            lib: 'fa',
            icon1: 'fa fa-facebook',
            icon2: 'mdi-facebook',
          },
          // {
          //   name: 'Twitter',
          //   url: '',
          //   lib: 'fa',
          //   icon1: 'fa fa-twitter',
          //   icon2: 'mdi-twitter',
          // },
          // {
          //   name: 'Youtube',
          //   url: '',
          //   lib: 'fa',
          //   icon1: 'fa fa-youtube',
          //   icon2: 'mdi-youtube',
          // },
          {
            name: 'Linkedin',
            url: 'https://www.linkedin.com/company/xpofranchise',
            lib: 'fa',
            icon1: 'fa fa-linkedin',
            icon2: 'mdi-linkedin',
          },
        ],
      }
    },
  }
</script>
<style lang="scss">
  .social-container {
    color: inherit;
    width: 80%;
    height: 100px;
    font-size: 1rem;
    margin: 20px 0;
  }
  .btn-wrapper {
    margin: 0;
    justify-content: space-between;
    font-size: xx-large;
    i {
      opacity: 0.8;
      transition: opacity 0.35s;

      &:hover {
        opacity: 1;
      }
    }
  }
</style>
